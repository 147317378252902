
/*@import stuff here*/

/* 
.ant-tabs-content {
  height: 100%;
} */

.no-radius .ant-input,
.no-radius .ant-select-selection {
  border-radius: 0;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}
.react-add-to-calendar__dropdown ul {
  padding-left: 0;
}
