.Login {
  text-align: center;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.Login-page {
  margin-top: 30vh;
}
