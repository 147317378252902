.ant-menu-dark {
  background: #46645b !important;
}

.ant-layout-header {
  background: #46645b !important;
}

.ant-menu {
  background: #46645b !important;
  border-bottom: #46645b !important;
}
